/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { checkIsAllsports } from "../domain_utils"
import BRANDS from "../brands"

const EXCLUDED_COUNTRY_FROM_INDEX = "by";

function Head({ description, lang, title, slug, apps }) {
  const { site } = useStaticQuery(
    graphql`
      query head {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const { t } = useTranslation()
  const siteUrl = site.siteMetadata.siteUrl || ""

  const allsportsParams = {
    aggregator: BRANDS.ALLSPORTS.aggregator,
    brand: BRANDS.ALLSPORTS.brand,
    author: BRANDS.ALLSPORTS.author,
    image: "/img/allsports_logo_type.png",
    imageLeft: "/img/allsportslogo_743_841.png",
    darkIcon: "/img/dark_favicon.ico",
    schema: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "AllSports",
      legalName: "ООО ОЛЛСПОРТС",
      logo: siteUrl + "/img/allsports_logo_type.png",
      url: siteUrl,
      sameAs: [
        "https://www.facebook.com/AllSports-355760931699171",
        "https://vk.com/public179007284",
      ],
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "+375-44-525-38-92",
          contactType: "technical support",
          availableLanguage: ["English", "Russian"],
          areaServed: "BY",
        },
        {
          "@type": "ContactPoint",
          telephone: "+374 91 777 931",
          contactType: "technical support",
          availableLanguage: ["Armenian", "English", "Russian"],
          areaServed: "AM",
        },
        {
          "@type": "ContactPoint",
          telephone: "+7-916-251-51-96",
          contactType: "technical support",
          availableLanguage: ["English", "Russian"],
          areaServed: "RU",
        },
      ],
    },
  }
  const sportBenefitParams = {
    aggregator: BRANDS.SPORTBENEFIT.aggregator,
    brand: BRANDS.SPORTBENEFIT.brand,
    author: BRANDS.SPORTBENEFIT.author,
    image: "/img/sportbenefit594594.png",
    imageLeft: "/img/sportbenefit743841.png",
    darkIcon: "/img/sportbenefit_dark_favicon.ico",
    schema: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "SportBenefit",
      legalName: "LTD SPORTBENEFIT",
      logo: siteUrl + "/img/sportbenefit594594.png",
      url: siteUrl,
      sameAs: [],
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "+37067285071",
          contactType: "technical support",
          availableLanguage: ["English", "Lithuanian"],
          areaServed: "LT",
        },
      ],
    },
  }
  const params = (() => {
    if (checkIsAllsports(lang)) {
      return allsportsParams
    } else {
      return sportBenefitParams
    }
  })()
  const metaDescription =
    description || t("head.description", { brand: params.brand })
  const metaTitle = `${title} | ${t("head.title", { brand: params.brand })}`
  const siteUrlWithSlug = `${siteUrl}${slug}`

  const isIndexingPage = "/discounts/" !== slug && lang !== EXCLUDED_COUNTRY_FROM_INDEX;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
    >
      <title>{metaTitle}</title>
      <html prefix="og: http://ogp.me/ns#"></html>
      <meta
        property="og:url"
        content={`${siteUrl}` + `/` + `${lang}` + `${slug}`}
      />
      <meta name="google" content="nositelinkssearchbox" />
      <meta name="google" content="notranslate" />
      {!isIndexingPage && <meta name="robots" content="noindex, nofollow" />}
      {isIndexingPage && <meta name="robots" content="index, follow" />}
      <meta
        name="copyright"
        content={t("head.copyright", { aggregator: params.aggregator })}
      />
      {siteUrl && params.image && (
        <meta property="og:image" content={siteUrl + params.image} />
      )}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaTitle && (
        <meta name="application-name" lang={lang} content={metaTitle} />
      )}
      {siteUrl && <base href={siteUrl} />}
      {siteUrlWithSlug && <link rel="canonical" href={siteUrlWithSlug} />}
      <meta property="og:locale" content={lang} />
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}
      {siteUrlWithSlug && <meta property="og:url" content={siteUrlWithSlug} />}
      <meta property="og:site_name" content={params.schema.name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={t("head.author", { brand: params.author })}
      />
      {metaDescription && (
        <meta name="twitter:description" content={metaDescription} />
      )}
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {siteUrl && params.imageLeft && (
        <meta name="twitter:image" content={siteUrl + params.imageLeft} />
      )}
      {siteUrl && params.darkIcon && (
        <meta
          name="msapplication-TileImage"
          content={siteUrl + params.darkIcon}
        />
      )}
      {siteUrl && params.image && (
        <meta name="image" content={siteUrl + params.image} />
      )}
      <meta name="theme-color" content="#FFC137" />
      {apps && <meta name="apple-itunes-app" content="app-id=1555419187" />}
      {apps && (
        <meta name="google-play-app" content="app-id=by.allsports.holder" />
      )}
      <link rel="icon" href={siteUrl + params.darkIcon} />
      <script type="application/ld+json">
        {JSON.stringify(params.schema)}
      </script>
    </Helmet>
  )
}

Head.defaultProps = {
  lang: "lt_en",
  meta: [],
  title: "",
  description: ``,
  apps: false,
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  apps: PropTypes.bool,
}

export default Head
