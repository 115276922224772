import React  from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import file from './demo.png'

function LicenseCategory({ title, slug }){
    const { t } = useTranslation()

    return(
        <div className="license">
            <h2>{ t(title) }</h2>
            <div className="picture-btn-wrapper">
                <img src={file}/>
                <Link className="btn" to={slug}>{ t('licenses.view') }</Link>
            </div>
        </div>
    )
}

export default LicenseCategory
